import { CreateSupplierInformationResponseInput, SupplierInformationResponse } from "@Eikochain/__generated__/graphql"
import { CreateSupplierInformationResponseMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier-information-response"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "urql"
import { Button } from "../button"
import { Form, } from "../form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Input } from "../input"
import { toast } from "sonner"
import { TrashIcon } from "lucide-react"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../select"
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../dialog"
import { useState } from "react"

interface SupplierInformationRequestFormProps {
    supplierRequest: SupplierInformationResponse["supplierInformationRequest"],
    onSuccess: (values: SupplierInformationResponse) => void,
}

const MAX_FILE_SIZE = 1024 * 1024 * 2
const SUPPORTED_FILE_FORMATS = ['pdf', 'png', 'jpeg', "plain"]

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export default function SupplierInformationRequestForm({
    supplierRequest,
    onSuccess,
}: SupplierInformationRequestFormProps) {
    const [mutationResult, executeMutation] = useMutation(CreateSupplierInformationResponseMutation)
    const [noProofDialogOpen, setNoProofDialogOpen] = useState(false)
    const requireProof = supplierRequest.requireProof === "true"

    const supplierInformationRequestFormSchema = z.object({
        name: z.string().min(1, "Name is required"),
        email: z.string().email(),
        companyName: z.string().min(1, "Company name is required"),
        stepValues: z.record(z.string(), z.union([z.string().min(1, "Field is required"), z.number()])),
        proofDocument: z.array(z
            .custom<File>(v => v instanceof File)
            .refine((file) => file.size < MAX_FILE_SIZE, "File must be less than 2MB")
            .refine((file) => SUPPORTED_FILE_FORMATS.includes(file?.type?.split("/").at(-1) as string), "File must be a PDF, PNG or JPEG")
        ),
        noProofReason: z.string().optional(),
    })

    const form = useForm<z.infer<typeof supplierInformationRequestFormSchema>>({
        resolver: zodResolver(supplierInformationRequestFormSchema),
        defaultValues: {
            proofDocument: []
        }
    })

    const onSubmit = async (values: z.infer<typeof supplierInformationRequestFormSchema>) => {
        if (requireProof && values.proofDocument.length === 0) {
            setNoProofDialogOpen(true)
        }

        if (requireProof && !values.noProofReason && values.proofDocument.length === 0) {
            form.setError("noProofReason", { message: "Please provide a reason for not uploading a proof document" })
            return
        }

        const response = await executeMutation({
            input: {
                supplierInformationRequestId: supplierRequest.id,
                name: values.name,
                email: values.email,
                companyName: values.companyName,
                stepValues: JSON.stringify(values.stepValues),
                document: values.proofDocument,
                noProofReason: values.noProofReason
            } satisfies CreateSupplierInformationResponseInput
        })

        const data = response.data?.createSupplierInformationResponse

        if (!data) {
            toast.error("Failed to submit response")
            return
        }

        if (data.success) {
            onSuccess(data)
        } else {
            toast.error(data.reason ?? "Failed to submit response")
        }
    }

    return (
        <Form {...form}>
            <form
                className="flex flex-col space-y-6"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <Dialog open={noProofDialogOpen} onOpenChange={setNoProofDialogOpen}>
                    <DialogContent>
                        <DialogTitle>Proof Document Required</DialogTitle>
                        <DialogDescription>You didn't upload a proof document. Please upload a proof document or supply a reason to continue.</DialogDescription>
                        <Controller
                            control={form.control}
                            name="noProofReason"
                            defaultValue=""
                            render={({ fieldState, field }) => (
                                <Input {...field} placeholder="Reason" className={fieldState.error && "border-red-300"} />
                            )} />
                        <p className="text-red-500">{form.formState.errors.noProofReason?.message}</p>
                        <Button
                            loading={mutationResult.fetching}
                            type="submit"
                            className="w-1/3"
                            onClick={form.handleSubmit(onSubmit)}
                        >Submit</Button>
                    </DialogContent>
                </Dialog>
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-8 w-full">
                    <Controller
                        control={form.control}
                        name="name"
                        defaultValue=""
                        render={({ fieldState, field }) => (
                            <div className="flex flex-col gap-y-2">
                                <label htmlFor="name">Name</label>
                                <Input {...field} placeholder="John Doe" className={fieldState.error && "border-red-300"} />
                                <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                            </div>
                        )}
                    />
                    <Controller
                        control={form.control}
                        name="email"
                        defaultValue=""
                        render={({ fieldState, field }) => (
                            <div className="flex flex-col gap-y-2">
                                <label htmlFor="name">Email</label>
                                <Input {...field} placeholder="john.doe@eikoiq.com" className={fieldState.error && "border-red-300"} />
                                <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                            </div>
                        )}
                    />
                    <Controller
                        control={form.control}
                        name="companyName"
                        defaultValue=""
                        render={({ fieldState, field }) => (
                            <div className="flex flex-col gap-y-2">
                                <label htmlFor="name">Company</label>
                                <Input {...field} placeholder="Acme Inc." className={fieldState.error && "border-red-300"} />
                                <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                            </div>
                        )}
                    />
                    {supplierRequest.steps.map((step) => (
                        <Controller
                            key={step?.stepNo}
                            control={form.control}
                            defaultValue={step?.inputType === "text" ? "" : step?.inputType === "yes/no" ? "no" : 0}
                            name={`stepValues.step_${step?.stepNo}`}
                            render={({ fieldState, field }) => (
                                <div className="flex flex-col gap-y-2">
                                    <div>
                                        <label htmlFor={`step-${step?.stepNo}`}>{step?.title} ({capitalize(step?.inputType!)}{step?.measurement !== "" && ` - ${step?.measurement}`})</label>
                                        <p className="font-light text-gray-800 text-sm">{step?.description === "" ? "-" : step?.description}</p>
                                    </div>
                                    {step?.inputType.toLowerCase() !== "yes/no" && <Input {...field}
                                        placeholder={step?.inputType === "text" ? "Some text" : "123"}
                                        className={fieldState.error && "border-red-300"}
                                        type={step?.inputType === "text" ? "text" : "number"}
                                    />}
                                    {step?.inputType.toLowerCase() === "yes/no" && (
                                        <Select onValueChange={field.onChange} defaultValue={"no"}>
                                            <SelectTrigger>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="yes">Yes</SelectItem>
                                                <SelectItem value="no">No</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    )}
                                    <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                                </div>
                            )}
                        />
                    ))}
                </div>
                <Controller
                    control={form.control}
                    name="proofDocument"
                    defaultValue={undefined}
                    render={({ fieldState, field }) => (
                        <div className="flex flex-col gap-y-2">
                            <label>Proof document(s)</label>
                            <div className="flex gap-1">
                                <div className="size-fit">
                                    <Input
                                        value=""
                                        onChange={(e) => {
                                            form.setValue("proofDocument", [...field.value, e.target.files?.[0]!])
                                        }}
                                        type="file"
                                        accept=".pdf,.png,.jpeg,.jpg,.txt"
                                        required={false}
                                        className={fieldState.error && "border-red-300"}
                                    />
                                </div>
                                <div className="flex-grow flex justify-start gap-1">
                                    {field.value.map((file, idx) => (
                                        <div key={idx} className="flex gap-2 min-w-[100px] justify-center items-center border rounded-lg border-gray-700">
                                            <span className="flex-grow text-center pl-2">{file.name}</span>
                                            <Button
                                                variant="destructive"
                                                className="p-3"
                                                type="button"
                                                onClick={() => form.setValue("proofDocument", field.value.filter((_, i) => i !== idx))}
                                            >
                                                <TrashIcon className="w-4 h-4" />
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                        </div>
                    )}
                />
                <div className="flex justify-center">
                    <Button loading={mutationResult.fetching} type="submit" className="w-1/3">Submit</Button>
                </div>
            </form>
        </Form >
    )
}
